<template>
  <div>
      <input :type="typeInput" ref="new_password" name="new_password"
             class="form-control form-control-lg"
             :placeholder="placeholder"
             v-on:input="$emit('input', $event.target.value)"
             maxlength="20"
            >
      <i :class="className" @click="changeClass"></i>
<!--    <i class="far fa-eye-slash"></i>-->
  </div>
</template>

<script>
export default {
  name: "InputPassword",
  props : {
    placeholder : {
      type: String,
      default(){
        return ''
      }
    },
  },
  data(){
    return {
      isShowPassword: false,
      className: 'far fa-eye eye-icon d-block',
      typeInput: 'password',
    }
  },
  methods: {
    changeClass : function (){
      this.isShowPassword = !this.isShowPassword;
      this.className = this.isShowPassword ? 'far fa-eye-slash eye-icon d-block' : 'far fa-eye eye-icon d-block';
      this.typeInput = this.isShowPassword ? 'text' : 'password';
    },
  }
}
</script>

<style scoped>
.eye-icon {
  position: absolute;
  top: 50%;
  right: 13px;
  transform: translateY(-50%);
  font-size: 19px;
  color: #5E616D;
}
</style>
