<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <div class="page-wrapper login">
      <div class="page-inner bg-brand-gradient">
        <div class="page-content-wrapper bg-transparent m-0">
          <div class="height-10 w-100 shadow-lg px-4 bg-brand-gradient">
            <div class="d-flex align-items-center container p-0">
              <div class="page-logo width-mobile-auto m-0 align-items-center justify-content-center p-0 bg-transparent bg-img-none shadow-0 height-9">
                <a href="javascript:void(0)"
                   class="page-logo-link press-scale-down d-flex align-items-center">
                  <img src="media/logo.svg" class="logo-edutalk" alt="Edutalk Tư Vấn"
                       aria-roledescription="logo">
                  <span class="page-logo-text mr-1"></span>
                </a>
              </div>
            </div>
          </div>
          <div class="flex-1">
            <div class="container py-4 py-lg-5 my-lg-5 px-4 px-sm-0">
              <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-5 col-xl-5 ml-auto">
                  <div class="card p-4 rounded-plus bg-faded">
                    <h1 class="fw-400 mb-3 d-sm-block">
                      Tạo mật khẩu mới
                    </h1>
                    <form id="new-password" class="form" @submit.prevent="handleSubmit(onSubmit)">
                      <div class="form-group">
                        <ValidationProvider vid="new_password" name="Mật khẩu mới" rules="required|min:6"
                                            v-slot="{ errors, classes }">
                          <label class="form-label">Mật khẩu mới</label>
                            <span class="box-item-input position-relative d-block">
                               <input-password v-model="form.new_password"  :placeholder="'Nhập mật khẩu mới'" :class="classes"></input-password>
                            </span>
                          <div class="fv-plugins-message-container">
                            <div data-field="name" data-validator="notEmpty"
                                 class="fv-help-block">{{ errors[0] }}
                            </div>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="form-group">
                        <ValidationProvider vid="confirm_password" name="Xác nhận mật khẩu" rules="required|min:6|confirmed:new_password"
                                            v-slot="{ errors, classes }">
                          <label class="form-label">Xác nhận mật khẩu</label>
                            <span class="box-item-input position-relative d-block">
                              <input-password v-model="form.confirm_password" :placeholder="'Xác nhận mật khẩu'" :class="classes"></input-password>
                            </span>
                          <div class="fv-plugins-message-container">
                            <div data-field="name" data-validator="notEmpty"
                                 class="fv-help-block">{{ errors[0] }}
                            </div>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="row no-gutters">
                        <div class="col-lg-12 pr-lg-1 my-2">
                          <button type="submit" id="login-submit" ref="login-submit"
                                  class="btn btn-primary btn-block btn-lg waves-effect waves-themed">
                            Hoàn thành
                          </button>
                        </div>
                        <div class="col-lg-12 mt-3 text-center justify-content-edutalk ">
                          <router-link to="/login" v-slot="{ href, navigate }">
                            <a :href="href"  title="Đăng ký tài khoản"
                               @click="navigate"
                               data-filter-tags="Đăng ký tài khoản"
                               class=" waves-effect waves-themed">
                              Quay lại đăng nhập
                            </a>
                          </router-link>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="col col-md-6 col-lg-7 hidden-sm-down">
                  <lottie-animation :path="'media/lottie/english-teacher.json'" />
                </div>
              </div>
              <div class="position-absolute pos-bottom pos-left pos-right p-3 text-center text-white">
                2021 © Edutalk by&nbsp;<a href="https://edutalk.edu.vn"
                                          class="text-white opacity-40 fw-500" title="edutalk.edu.vn"
                                          target="_blank">edutalk.edu.vn</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>
<!-- Load login custom page styles -->

<script>
import {mapGetters} from "vuex";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import {CHANGE_NEW_PASSWORD} from "@/core/services/store/auth.module";
import InputPassword from "./InputPassword";
export default {
  name: "edutalk-forgot-password",
  components: {
    LottieAnimation,
    InputPassword
  },
  data() {
    return {
      publicPath: process.env.VUE_APP_BASE_URL,
      state: "signin",
      password: "password",
      passwordNew: "password",
      form: {
        new_password: "",
        confirm_password: ""
      },
      check_error: false
    };
  },
  computed: {
    ...mapGetters(["getUserCode"]),
  },
  methods: {
    onSubmit() {
      let new_password = this.form.new_password;
      let confirm_password = this.form.confirm_password;
      this.$store.dispatch(CHANGE_NEW_PASSWORD, {
        new_password : new_password,
        confirm_password: confirm_password,
        user_id : this.getUserCode
      }).then(() => {
        this.$router.push({name : 'login'}).then(() => {
          this.$message({
            type: 'success',
            message : 'Bạn đã đổi mật khẩu thành công!',
            showClose : true
          });
        })
      }).catch(() => {
        this.$message({
          type: 'error',
          message: 'Sever đang bận vui lòng thử lại!',
          showClose: true
        });
      })
    },
  }
};
</script>
<style scoped>
.was-validated .form-control:invalid, .form-control.is-invalid{
  background: none;
}
</style>
